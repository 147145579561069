<template>
  <div class="main">
    <div class="content">
      <h1>选&nbsp;&nbsp;手&nbsp;&nbsp;资&nbsp;&nbsp;料</h1>

      <el-descriptions class="margin-top" title="" :column="2" :size="size" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            头像
          </template>
          <img :src="playerInfo.avatar"/>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            用户名
          </template>
          {{ playerInfo.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            称号
          </template>
          {{ playerInfo.nickName }}
        </el-descriptions-item>
        
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            来自
          </template>
          <el-tag size="small">{{ playerInfo.source }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            身高
          </template>
          174CM
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            体重
          </template>
          75KG
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Footer
  },
  data() {
    return {
      playerId: 0,
      playerInfo: null
    }
  },
  created() {
    this.playerId = this.$route.query.playerId
    this.loadPlayerInfo()
  },
  methods: {
    loadPlayerInfo() {
      axios.get('/cms/front/getPlayerInfoById?playerId=' + this.playerId)
        .then(response => {
          this.playerInfo = response.data.data
        })
        .catch(error => {
          console.error('请求后端接口失败:', error)
        })
    }
  }
}
</script>

<style scoped>
.main {
  background-color: black;
  border: 1px solid black;
  min-height: 920px;
  font-size: 12px;
}

.content {
  width: 80%;
  min-height: 450px;
  background-color: #181818;
  margin-top: 30px;
  padding-top: 30px;
  margin: 0px auto;
  color: white;
}

.content h1 {
  text-align: center;
}

.content img {
  float: left;
  width: 200px;
}
</style>

