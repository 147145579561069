<template>
  <div class="main">
    <div class="content">
      <h1>格&nbsp;&nbsp;斗&nbsp;&nbsp;讲&nbsp;&nbsp;堂</h1>
      <h1 style="color: palevioletred;">Sorry！暂未开放！</h1>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pagesize: 10,
      total: 1
    }
  },
  created() {
    this.handleData()
  },
  methods: {
    handleSizeChange(size) {
      this.pagesize = size
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.handleData()
    },
    handleData() {
      axios.get('/cms/front/firstContentPages?contentType=notice')
        .then(response => {
          this.tableData = response.data.rows
          this.total = response.data.total
        })
        .catch(error => {
          console.error('请求后端接口失败:', error)
        })
    }
  }
}
</script>

<style scoped>
.main {
  background-color: black;
  border: 1px solid black;
  font-size: 12px;
}

.content {
  width: 80%;
  min-height: 450px;
  background-color: #181818;
  margin-top: 30px;
  padding-top: 30px;
  margin: 0px auto;
  color: white;
  text-align: center;
}

.el-pagination {
  text-align: center;
  margin-top: 30px;
}
</style>

