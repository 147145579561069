<template>
  <div class="main">
    <div class="content">
      <h1 style="text-align: center;">赛&nbsp;&nbsp;事&nbsp;&nbsp;直&nbsp;&nbsp;播</h1>

      <el-row :gutter="12">
        <el-col :span="6" v-for="item in tableData" :key="item.id">
          <el-card shadow="hover" style="background-color: #181818; border-color:#181818;">
            <img :src="item.cover" class="live-1-image" style="width:290px; height:180px;" @click="goDetail(item)">
            <p>{{ item.title }}</p>
          </el-card>
        </el-col>
      </el-row>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"      
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pagesize: 4,
      total: 1
    }
  },
  created() {
    this.handleData()
  },
  methods: {
    handleData() {
      axios.get('/cms/front/firstContentPages?contentType=live')
        .then(response => {
          this.tableData = response.data.rows.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize)
          this.total = response.data.total
        })
        .catch(error => {
          console.error('请求后端接口失败:', error)
        })
    },
    goDetail(row){
      this.$router.push({
        path: '/detail',
        query: {'contentId': row.id}
      });
    },
    handleSizeChange: function (size) {
      this.pagesize = size;
      this.handleData();
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleData();
    }
  }
}
</script>

<style scoped>
.main {
  background-color: black;
  border: 1px solid black;
  font-size: 12px;
}

.content {
  width: 80%;
  min-height: 450px;
  background-color: #181818;
  margin-top: 30px;
  padding-top: 30px;
  margin: 0px auto;
  color: white;
}

.el-row {
  flex: 1;
  margin-bottom: 30px;
}

.content p {
  color: white;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styleTable {
  background-color: transparent !important;
  border-collapse: separate; 
  border-spacing: 0px 10px;
  width: 100%;
  cursor: pointer; 
  background: #181818;
  border: 1px solid #181818;
}

::v-deep .el-table th.el-table__cell,
::v-deep .el-table th,
::v-deep .el-table tr {
  color: white;
  background-color: transparent !important;
}

::v-deep .el-table::before {
  background: none;
}

.styleTable >>> .el-table__body tr:hover > td {
  background-color: rgba(255, 0, 0, 0.121) !important;
}

:deep .el-pagination .btn-prev,
:deep .el-pagination .btn-next {
  background-color: transparent !important;
  color: white;
}

:deep .el-pagination .el-pager li:not(.active):not(.disabled):hover {
  background-color: white !important;
  color: cadetblue;
}

:deep .el-pagination .el-pager li:not(.active):not(.disabled) {
  background-color: transparent !important;
  color: white;
}

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: #75fbff !important; 
  color: #01133c;
}

.el-pagination {
  text-align: center;
  margin-top: 30px;
}
</style>

