<template>
  <div class="main">
    <div class="content">
      <h1>认&nbsp;&nbsp;证&nbsp;&nbsp;查&nbsp;&nbsp;询</h1>

      <div class="search-container">
        <div class="search-box">
          <div class="search-input">
            <el-input
              v-model="certificateNo"
              placeholder="请输入证书编号"
              prefix-icon="el-icon-search"
              clearable>
            </el-input>
            <el-button type="danger" @click="searchCertificate">查询认证</el-button>
          </div>
          
          <div class="certificate-types">
            <p>可查询以下类型证书：</p>
            <ul>
              <li>级位证书查询</li>
              <li>段位证书查询</li>
              <li>教练员证书查询</li>
              <li>裁判员证书查询</li>
              <li>级位认证官证书查询</li>
              <li>段位认证官证书查询</li>
            </ul>
          </div>
        </div>

        <!-- 查询结果展示区域 -->
        <div class="search-result" v-if="showResult">
          <!-- 这里可以添加查询结果的展示内容 -->
        </div>
      </div>
    </div>

    <!-- 底部 -->
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pagesize: 10,
      total: 1,
      certificateNo: '',
      showResult: false
    }
  },
  created() {
    this.handleData()
  },
  methods: {
    handleData() {
      // 加载所有通知公告
      axios.get('/cms/front/firstContentPages?contentType=notice')
        .then(response => {
          this.tableData = response.data.rows
          this.total = response.data.total
        })
        .catch(error => {
          console.error('请求后端接口失败:', error)
        })
    },
    searchCertificate() {
      // 这里添加证书查询逻辑
      if (!this.certificateNo) {
        this.$message.warning('请输入证书编号');
        return;
      }
      // TODO: 实现查询逻辑
    }
  }
}
</script>

<style scoped>
.main {
  background-color: black;
  border: 1px solid black;
  font-size: 12px;
}

.content {
  width: 80%;
  min-height: 450px;
  background-color: #181818;
  margin-top: 30px;
  padding-top: 30px;
  margin: 0px auto;
  color: white;
}

.content h1 {
  text-align: center;
}

.styleTable {
  background-color: transparent !important;
  border-collapse: separate;
  border-spacing: 0px 10px;
  width: 100%;
  cursor: pointer;
  background: #181818;
  border: 1px solid #181818;
}

/* el-table背景透明 */
.styleTable ::v-deep .el-table th.el-table__cell,
::v-deep .el-table th,
::v-deep .el-table tr {
  color: white;
  background-color: transparent !important;
}

/* el-table底部边框样式修改 */
::v-deep .el-table::before {
  background: none;
}

/* el-table 鼠标悬停样式修改 */
.styleTable >>> .el-table__body tr:hover > td {
  background-color: rgba(255, 0, 0, 0.121) !important;
}

::v-deep .el-pagination .el-pager li:not(.active):not(.disabled):hover {
  background-color: white !important;
  color: red;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: red;
  color: white;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: transparent !important;
  color: #ff2832;
}

::v-deep .el-pagination.is-background .btn-prev {
  background-color: transparent !important;
  color: #ff2832;
}

::v-deep .el-pagination.is-background .btn-next {
  background-color: transparent !important;
  color: #ff2832;
}

.el-pagination {
  text-align: center;
  margin-top: 30px;
}

/* 新增查询相关样式 */
.search-container {
  width: 80%;
  margin: 30px auto;
  color: white;
}

.search-box {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 30px;
  text-align: center;
}

.search-input {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.search-input .el-input {
  width: 400px;
}

.certificate-types {
  text-align: left;
  margin-top: 20px;
}

.certificate-types p {
  color: #ff2832;
  font-size: 16px;
  margin-bottom: 10px;
}

.certificate-types ul {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.certificate-types li {
  color: #999;
  font-size: 14px;
  line-height: 24px;
}

.certificate-types li:hover {
  color: white;
  cursor: pointer;
}

/* 修改Element UI组件样式 */
:deep(.el-input__inner) {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none;
  color: white;
}

:deep(.el-input__inner:focus) {
  border-color: #ff2832;
}

:deep(.el-input__prefix) {
  color: #666;
}

.el-button--danger {
  background-color: #ff2832;
  border-color: #ff2832;
  padding: 0 30px;
}

.el-button--danger:hover {
  background-color: #ff4d4d;
  border-color: #ff4d4d;
}
</style>
