<template>
  <div id="app">
    <div class="main-center">
      <div class="top">
        <img class="logo" src="@/assets/logo.png">
        <!-- 导航 -->
        <ul class="top-menu">
          <li v-for="nav in navList" :key="nav.id"  :class="{ liActive: activeLink == nav.slug }" @click="go(nav.slug)">
              {{ nav.name }}
          </li>
        </ul>

        <!-- 用户图标 -->
        <div class="user">
          <el-avatar icon="el-icon-user-solid"></el-avatar>
        </div>
      </div>
    </div>

    <!-- 路由出口 -->
    <div class="router-view">
      <router-view/>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Footer
  },
  data() {
    return {
      navList: [],
      activeLink: null
    }
  },
  created() {
    this.activeLink = this.$route.query.link
    this.init()
  },
  methods: {
    init() {
      // 加载导航
      axios.get('/cms/front/channelList?siteId=2')
        .then(response => {
          this.navList = response.data.data
        })
        .catch(error => {
          console.error('请求后端接口失败:', error)
        })
    },
    go(link) {
      if(link == null || link == '') {
        this.$router.push("/")
      } else {
        this.$router.push({
          path: link,
          query: {'link': link}
        })
      }
    }
  },
  watch: {
    '$route'(to) {
      this.activeLink = to.query.link
    }
  }
}
</script>

<style>
#app {
  background-color: black;
  border: 1px solid black;
  font-size: 12px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-center {
  width: 76%;
  margin: 0px auto;
}

.top {
  border:1px solid black;
  height: 80px;
  background-color: black;
}

.logo {
  width: 128px;
  height: 68px;
  float: left;
  position: relative;
  top: 8px;
}

.top-menu {
  width: 600px;
  height: 40px;
  font-size: 12px;
  background-color: red;
  border-radius: 6px;
  color: black;
  float: left;
  margin-left: 30px;
  margin-top: 30px;
}

.top-menu li {
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  display: inline-block;
  margin: 0 10px;
  list-style: none;
}

.top-menu li:hover {
  cursor: pointer;
  color: aliceblue;
}

.liActive {
  font-weight: bold;
  color: honeydew;
}

.user {
  float: right;
  margin-top: 30px;
  margin-right: 20px;
}
html, body {
  background-color: #000;
}

.router-view {
  flex: 1;
}
</style>
