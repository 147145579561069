<template>
  <div class="main">
    <div class="container">
      <div class="content">
        <h1>{{ contest.title }}</h1>
        <p class="nei" v-html="contest.playLink"> </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      contestId: null,
      contest: null
    }
  },
  created() {
    this.contestId = this.$route.query.contestId
    this.init()
  },
  methods: {
    init() {
      axios.get('/cms/front/getContestById?contestId='+ this.contestId)
        .then(response => {
          this.contest = response.data.data
        })
        .catch(error => {
          console.error('请求后端接口失败:', error)
        })
    }
  }
}
</script>

<style scoped>
.main {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  width: 100%;
  padding: 30px 0;
}

.content {
  width: 80%;
  min-height: 450px;
  background-color: #181818;
  margin: 0 auto;
  padding: 30px 0;
  color: white;
}

.content h1 {
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 24px;
  font-weight: normal;
}

.content .nei {
  width: 90%;
  min-width: 700px;
  margin: 0 auto;
  line-height: 1.8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content .nei iframe,
.content .nei video {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.content img {
  max-width: 600px;
  display: block;
  margin: 15px auto;
}
</style>
