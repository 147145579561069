<template>
  <div class="main">
    <div class="main-center">

      
      <!-- 轮播 -->
      <!-- <div class="carouselContiner">
         <el-carousel class="carousel" :interval="3000" height="450px" width="358px"  >
          <el-carousel-item v-for="item in carouselList" :key="item.id" >
              <img style="width:700px;heigth:208px;object-fit: cover;" :src="item.img" >
            <p style="color:white; font-size:1.3em;line-height:20px;">{{item.title}}</p>
          </el-carousel-item>
        </el-carousel>
      </div> -->

      <!-- 副新闻容器 -->
      <!-- <div class="title2">
        <ul>
          <li v-for="item in title2List" :key="item.id">
            <img :src="item.img" class="title2-image">
            <p>{{item.title}}</p>
          </li>
        </ul>
      </div> -->

      <!-- 新闻列表 -->
      <div class="newsList">
        <table  cellsapcing="0" style="color:white;border-collapse:separate; border-spacing:0px 10px;">
          <tr v-for="item in newsList" :key="item.id" style="background-color:#181818;border-radius:3px;" >
            <td><img :src="item.cover" class="live-1-image"></td>
            <td> 
              <table  height="140px" cellsapcing="0" style="color:white;border-collapse:separate; border-spacing:0px 10px;">
                <tr height="20px">
                  <td class="newsList-title" @click="goDetail(item.id)">{{item.title}}</td>
                  <td class="newsList-date">{{item.updateTime}}</td>
                </tr>
                <tr >
                  <td colspan="2" class="newsList-content">{{ formatContent(item.content) }}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        
        <!-- 分页 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pagesize"      
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>  
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { cleanHtml } from '@/utils/htmlUtils';

export default {
  data() {
    return {
      navList:[],
      carouselList:[
        {
          id:1,
          title:"111最佳MMA比赛榜单，熊竞楠仅排第二？",
          img: require("@/assets/live-m-1.png"),
        },
        {
          id:2,
          title:"222最佳MMA比赛榜单，熊竞楠仅排第二？",
          img: require("@/assets/live-m-2.png"),
        },
        {
          id:3,
          title:"333最佳MMA比赛榜单，熊竞楠仅排第二？",
          img: require("@/assets/live-m-3.png"),
        },
      ],
      title2List:[
        {
          id:1,
          title:"2020最佳MMA比赛榜单，熊竞楠仅排第二？",
          img: require("@/assets/newsList-1.png"),
        },
        {
          id:2,
          title:"恭喜！张伟丽荣获MMA年度最佳女选手 ",
          img: require("@/assets/newsList-1.png"),
        },
        {
          id:3,
          title:"CCTV5今晚18:35｜太平洋建设·亚洲MMA女冠军鏖战韩国迷妹子等5场！",
          img: require("@/assets/newsList-1.png"),
        },
        {
          id:4,
          title:"中国昔日MMA女王vs印度国宝级拳手",
          img: require("@/assets/newsList-1.png"),
        },
      ],
      // newsList:[
      //   {
      //     id:1,
      //     title:"2020最佳MMA比赛榜单，熊竞楠仅排第二？",
      //     date: '2024年4月16日',
      //     img: require("@/assets/newsList-1.png"),
      //     content:"过去一年，由于受到疫情冲击，ONE冠军赛野心勃勃的办赛计划受到影响，在亚洲各国严厉的管控措施下，被迫陷入短暂停摆期，然而恢复办赛后，ONE几乎以一周一次旗舰赛的频率填补了2020年的剩余时间。回顾这一年ONE仅在旗舰赛上就安排了139场对决，综合格斗（MMA）赛事依然占据主流，进行了86场高水平对阵，其中很多经典比赛值得再次复盘，下面5场对决最终有幸入选年度最佳MMA比赛榜单",
      //   },
      //   {
      //     id:2,
      //     title:"恭喜！张伟丽荣获MMA年度最佳女选手 ",
      //     date: '2024年4月16日',
      //     img: require("@/assets/newsList-1.png"),
      //     content:"北京时间12月22日，ESPN DC&RC公布了年度最佳榜单，来自中国的女拳王张伟丽当选年度最佳女选手。此外，年度最佳男选手授予了亚历克斯·佩雷拉；年度最佳KO为钱德勒KO弗格森；年度最佳降服为安德拉德降服莱莫斯。就在上个月举行的UFC女子草量级比赛中，张伟丽以碾压的姿态第二回合裸绞战胜卡拉·埃斯帕扎，重夺UFC女子草量级冠军，出色的表现，不愧被称为“六边形女战士”为了拿回金腰带，张伟丽拼尽了全力。比赛前，她和团队准备了很多种终结对方的方法。防摔是着重加强的训...",
      //   },
      //   {
      //     id:3,
      //     title:"CCTV5今晚18:35｜太平洋建设·亚洲MMA女冠军鏖战韩国迷妹子等5场！",
      //     date: '2024年4月16日',
      //     img: require("@/assets/newsList-1.png"),
      //     content:"中央电视台体育频道10日讯:今晚18:35中央电视台体育频道(CCTV5),将为您呈现CKF115:太平洋建设·CKF国际搏击吉首站,5场精彩比赛。您将看到:您将看到:①男子61KG级姚志奎(中)VS斯万普(印);②男子66KG级曹政(中)VS上妻慎治(日);③男子69KG级李森(中)VS阿米尔(俄);④男子75KG级哈斯特尔(中)VS冯本汝(中国香港);⑤女子56KG级董华香(中)VS珍世姬(韩)。CKF是以中国功夫为国家...",
      //   },
      //   {
      //     id:4,
      //     title:"中国昔日MMA女王vs印度国宝级拳手",
      //     date: '2024年4月16日',
      //     img: require("@/assets/newsList-1.png"),
      //     content:"最近，ONE冠军赛公布了9月3日的女子原子量级世界八人赛对阵表，中国选手孟博首轮将对战《摔跤吧，爸爸》原型人物，也是印度国宝级摔跤手瑞图·佛加。而就在不到一个月之前，瑞图还击败了中国首位MMA世锦赛金牌获得者“格斗林妹妹”林荷琴。 两个月内连续对战两位中国女子名将，这一战也可谓是“冤家路窄”了。这一次的女子原子量级世界八人赛汇聚了众多各国实力女将，比如日本超新星平田树，韩国一姐韩瑞熙，ONE冠军赛踢拳、泰拳双冠王斯坦普·菲尔泰斯等等。而瑞图也堪称是印...",
      //   },
      //   {
      //     id:5,
      //     title:"2020最佳MMA比赛榜单，熊竞楠仅排第二？",
      //     date: '2024年4月16日',
      //     img: require("@/assets/newsList-1.png"),
      //     content:"过去一年，由于受到疫情冲击，ONE冠军赛野心勃勃的办赛计划受到影响，在亚洲各国严厉的管控措施下，被迫陷入短暂停摆期，然而恢复办赛后，ONE几乎以一周一次旗舰赛的频率填补了2020年的剩余时间。回顾这一年ONE仅在旗舰赛上就安排了139场对决，综合格���（MMA）赛事依然占据主流，进行了86场高水平对阵，其中很多经典比赛值得再次复盘，下面5场对决最终有幸入选年度最佳MMA比赛榜单",
      //   },
      // ],
      newsList:[],
      currentPage:1, 
      pagesize:5,    
      total:1,
      activeLink: null,
    };
  },
  computed: {

  },
  mounted() {
   
  },
  created(){
    this.activeLink = this.$route.query.link  ;
    // 初始化页面数据
    this.init();
    this.handleData();
  },
  methods: {
    init(){
      // 加载导航
      axios.get('/cms/front/channelList?siteId=2')
        .then(response => {
          this.navList = response.data.data;
          // console.log(response.data);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });
      // 加载所有通知公告
      axios.get('/cms/front/firstContentPages?contentType=news')
        .then(response => {
          this.tableData = response.data.rows;
          this.total = response.data.total;
          // console.log(response.data);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });
    },
    go(link){
      if(link == null || link == ''){
        this.$router.push("/");
      }else{
        this.$router.push({
          path: link,
          query: {'link': link}
        });
      }
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      console.log(this.currentPage)  //点击第几页
      this.handleData()
    },
    handleData() {
      // 加载所有通知公告
      axios.get('/cms/front/firstContentPages?contentType=news&pageNum=' + this.currentPage + '&pageSize='+ this.pagesize)
        .then(response => {
          this.newsList = response.data.rows;
          this.total = response.data.total;
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });
    },
    goDetail(id){
      this.$router.push({
        path: '/detail',
        query: {'contentId': id}
      });
    },
    formatContent(content) {
      return cleanHtml(content, 145);
    }
  }
}
</script>

<style scoped>
.main{
  background-color: black;
  border:1px solid black;
  font-size: 12px; 
}
.top-menu li:hover{
  cursor: pointer;
  color: aliceblue;
}

.liActive{
  font-weight: bold;
  color: honeydew;
}
.main-center{
  width: 76%;
  margin: 0px auto;
}
.top{
  border:1px solid black;
  height: 80px;;
  background-color: black;
}
.logo{
  width: 128px;
  height: 68px;
  float: left;
  position: relative;
  top: 8px;
}
.top-menu {
  width: 600px;
  height: 40px;
  font-size: 12px;
  background-color: red;
  border-radius: 6px;
  color: black;
  float: left;
  margin-left: 30px;;
  margin-top: 30px;
}
.top-menu li{
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  /* border:1px solid black; */
}
a{
  text-decoration: none;
}

.top-menu-right img{
  height: 46px;
  float: right;
  position: relative;
  top:-3px;
  left: 40px;;
}
.top-menu ul {
  list-style-type: none;
  padding: 0;
}
.top-menu li {
  display: inline-block;
  margin: 0 10px;
}

.user{
  float: right;
  margin-top:30px;
  margin-right: 20px;
}

/* ============================================================================== */
.carouselContiner{
  width: 50%;
  height: 400px;
  /* border:1px solid red; */
  float: left;
  border-radius: 3px;
}
.carouselContiner p{
  position: absolute;
  top:360px;
}
::v-deep .el-carousel__button {
  width: 68px;
  height: 9px;
  background: #cbd9e2;
  border-radius: 22px 22px 22px 22px;
  margin-top:-90px;
}
/* ============================================================================== */
.title2{
  width: 49%;
  height: 400px;
  /* border:1px solid green; */
  float: right;
  border-radius: 3px;
}

.title2 ul{
  margin:0px;
  padding:0px;
}
.title2 li{
  float: left;
  list-style:none;
  width: 280px;
  height: 175px;
  border-radius: 4px;
  margin:8px;
  /* border:1px solid green; */
}
.title2 li p{
  background-color: white;
  opacity: 0.85;
  padding: 0px;
  padding-left: 5px;
  margin-top:-27px;
  height: 25px;
  line-height: 25px;
  border-radius: 3px;
}
.title2-image{
  width: 280px;
  height: 170px;
  object-fit:cover;
}
.newsList{
  width: 100%;
  /* border:1px solid white; */
  float: left;
  clear: both;
  margin-top:20px;
  border-radius: 3px;
}
.newsList table{
  width: 100%;
  /* border:1px solid white; */
}
.newsList-title{
  font-size: 1.3em;
  font-weight: bold;
  float: left;
  margin-left: 20px;
  cursor: pointer;
}
.newsList-date{
  float: right;
  margin-right: 20px;
}
.live-1-image{
  width: 182px;
  height: 122px;
  padding:10px;
  border-radius: 3px;
}
.newsList-content{
  width: 980px;
  max-height: 100px;
  float: left;
  margin-left: 20px;
  margin-top: 10px;
  line-height: 20px;
  overflow:hidden;
  text-overflow:ellipsis;
  /*禁止换行显示*/
  white-space:nowrap;
}
.pages{
  width: 20%;
  height: 20px;
  float: right;
  margin-top:10px;
}

::v-deep .el-pagination .el-pager li:not(.active):not(.disabled):hover {
  background-color: white !important;
  color: red;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: red;
  color:white;
}
::v-deep .el-pagination.is-background .el-pager  li:not(.disabled){
	background-color: transparent !important;
  color:#ff2832;
}
::v-deep .el-pagination.is-background  .btn-prev{
	background-color: transparent !important;
  color:#ff2832;
}
::v-deep .el-pagination.is-background  .btn-next{
	background-color: transparent !important;
  color:#ff2832;
}
.el-pagination {
  /* border:1px solid red;  */
  text-align: center;
}

.footer{
  width: 100%;
  height: 320px;
  margin-top:20px;
  /* border:1px solid red; */
  float: left;
  clear: both;
  background-color: #181818;
}

.footer-center{
  width: 80%;
  height: 300px;
  margin: 0px auto;
  /* border:1px solid black; */
}
.footer-center ul{
  padding:0px;
  margin: 0px;
}
.footer-center li{
  float: left;
  height: 30px;
  width: 260px;
  text-align: center;
  list-style: none;
}
</style>
