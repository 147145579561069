<template>
  <div class="main">
    <div class="content" style="background-color: #181818;">
      <h1 style="text-align: center;">精&nbsp;&nbsp;彩&nbsp;&nbsp;视&nbsp;&nbsp;频</h1>
      <el-row :gutter="12">
        <el-col :span="6" v-for="item in tableData" :key="item.id">
          <el-card shadow="hover" style="background-color: #181818; border-color:#181818;">
            <img :src="item.cover" class="live-1-image" style="width:290px; height:180px;" @click="goDetail(item)">
            <p>{{ item.title }}</p>
          </el-card>
        </el-col>
      </el-row>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"      
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pagesize: 10,
      total: 1
    }
  },
  created() {
    this.handleData()
  },
  methods: {
    goDetail(row) {
      this.$router.push({
        path: '/detail',
        query: {'contentId': row.id}
      })
    },
    handleSizeChange(size) {
      this.pagesize = size
      this.handleData()
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.handleData()
    },
    handleData() {
      axios.get('/cms/front/firstContentPages?contentType=video')
        .then(response => {
          this.tableData = response.data.rows.slice((this.currentPage-1)*this.pagesize, this.currentPage*this.pagesize)
          this.total = response.data.total
        })
        .catch(error => {
          console.error('请求后端接口失败:', error)
        })
    }
  }
}
</script>

<style scoped>
/* 删除头部导航相关样式,保留其他样式 */
.main {
  background-color: black;
  border: 1px solid black;
  font-size: 12px;
}

.content {
  width: 80%;
  min-height: 450px;
  background-color: #181818;
  margin-top: 30px;
  padding-top: 30px;
  margin: 0px auto;
  color: white;
}

.content p {
  color: white;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 分页样式 */
:deep .el-pagination .btn-prev,
:deep .el-pagination .btn-next {
  background-color: transparent !important;
  color: white;
}

:deep .el-pagination .el-pager li:not(.active):not(.disabled):hover {
  background-color: white !important;
  color: cadetblue;
}

:deep .el-pagination .el-pager li:not(.active):not(.disabled) {
  background-color: transparent !important;
  color: white;
}

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: #75fbff !important;
  color: #01133c;
}

.el-pagination {
  text-align: center;
  margin-top: 30px;
}
</style>

