<template>
  <div class="main">
    <div class="content">
      <h1>{{ content.title }}</h1>
      <h3>{{ content.subtitle }}</h3>
      <h4>{{ content.updateTime }}       {{ content.author }}  </h4>
      <p><img :src='content.cover'/></p>
      <p class="nei" v-html="content.content"> </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {
  },
  data() {
    return {
      contentId: null,
      content: null
    }
  },
  created() {
    this.contentId = this.$route.query.contentId
    this.init()
  },
  methods: {
    init() {
      // 获取内容详情
      axios.get('/cms/front/getContentById?contentId='+ this.contentId)
        .then(response => {
          this.content = response.data.data
          if (this.content && this.content.content) {
            // 创建一个临时的div来解析HTML
            const div = document.createElement('div')
            div.innerHTML = this.content.content
            
            // 找到所有图片并移除它们的宽度和高度属性
            const images = div.getElementsByTagName('img')
            for (let img of images) {
              img.removeAttribute('width')
              img.removeAttribute('height')
              img.removeAttribute('style')
            }
            
            // 更新处理后的内容
            this.content.content = div.innerHTML
          }
        })
        .catch(error => {
          console.error('请求后端接口失败:', error)
        })
    }
  }
}
</script>

<style scoped>
.main {
  background-color: black;
  font-size: 12px;
}

.content {
  width: 80%;
  min-height: 450px;
  height: auto;
  background-color: #181818;
  margin-top: 30px;
  padding-top: 30px;
  margin: 0px auto;
  color: white;
}

.content h1,h3,h4,p {
  text-align: center;
}

.content .nei {
  width: 90%;
  min-width: 700px;
  margin: 0px auto;
}

.content .nei img,
.content .nei >>> img,
.content .nei ::v-deep img {
  max-width: 100% !important;
  height: auto !important;
  width: auto !important;
  object-fit: contain !important;
}

.content img {
  max-width: 600px;
}
</style>
