<template>
  <div class="main">
    <div class="content">
      <h1>通&nbsp;&nbsp;知&nbsp;&nbsp;公&nbsp;&nbsp;告</h1>

      <el-table :data="tableData" 
        :header-cell-style="{borderColor:'#181818'}" 
        :cell-style="{borderColor:'#181818'}"  
        :row-style="{borderColor:'#181818'}"  
        @row-click="goDetail"
        class="styleTable">
        <el-table-column width="30"></el-table-column>
        <el-table-column prop="title"></el-table-column>
        <el-table-column prop="updateTime" width="180"></el-table-column>
      </el-table>
      
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"      
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pagesize: 10,
      total: 1
    }
  },
  created() {
    this.handleData()
  },
  methods: {
    goDetail(row) {
      this.$router.push({
        path: '/detail',
        query: {'contentId': row.id}
      })
    },
    handleSizeChange(size) {
      this.pagesize = size
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.handleData()
    },
    handleData() {
      axios.get('/cms/front/firstContentPages?contentType=notice&pageNum=' + this.currentPage + '&pageSize='+ this.pagesize)
        .then(response => {
          this.tableData = response.data.rows
          this.total = response.data.total
        })
        .catch(error => {
          console.error('请求后端接口失败:', error)
        })
    }
  }
}
</script>

<style scoped>
/* 删除头部导航相关样式,保留其他样式 */
.main {
  background-color: black;
  border: 1px solid black;
  font-size: 12px;
}

.content {
  width: 80%;
  min-height: 450px;
  background-color: #181818;
  margin-top: 30px;
  padding-top: 30px;
  margin: 0px auto;
  color: white;
}

.content h1 {
  text-align: center;
}

/* 表格样式 */
.styleTable {
  background-color: transparent !important;
  border-collapse: separate;
  border-spacing: 0px 10px;
  width: 100%;
  cursor: pointer;
  background: #181818;
  border: 1px solid #181818;
}

/* el-table样式 */
.styleTable ::v-deep .el-table th.el-table__cell,
::v-deep .el-table th,
::v-deep .el-table tr {
  color: white;
  background-color: transparent !important;
}

::v-deep .el-table::before {
  background: none;
}

.styleTable >>> .el-table__body tr:hover > td {
  background-color: rgba(255, 0, 0, 0.121) !important;
}

/* 分页样式 */
::v-deep .el-pagination .el-pager li:not(.active):not(.disabled):hover {
  background-color: white !important;
  color: red;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: red;
  color: white;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: transparent !important;
  color: #ff2832;
}

::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .btn-next {
  background-color: transparent !important;
  color: #ff2832;
}

.el-pagination {
  text-align: center;
  margin-top: 30px;
}
</style>

