<template>
  <div class="footer">
    <div class="footer-center">
      <table width="100%" height="100%">
        <tr>
          <td rowspan="2" width="13%" style="text-align:center">
            <img src="@/assets/logo.png" width="80%">
            <h3 style="text-align:center;color:white;">微信公众号</h3>
          </td>
          <td rowspan="2" width="13%" style="text-align:center">
            <img src="@/assets/logo.png" width="80%">
            <h3 style="text-align:center;color:white;">抖音二维码</h3>
          </td>
          <td width="13%" height="50%" style="text-align:center;vertical-align: text-top;">
            <h3 style="text-align:center;color:white;">地方协会</h3>
            <span style="color:red;">LOCAL ASSOCIATIONS</span>
          </td>
          <td>
            <ul class="hui" style="color:white;">
              <li>武汉</li>
              <li>延边</li>
              <li>成都</li>
              <li>上海</li>
              <li>西安</li>
              <li>杭州</li>
              <li>深圳</li>
              <li>乌鲁木齐</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td width="13%" style="text-align:center; vertical-align: text-top;">
            <h3 style="text-align:center;color:white;">友情链接</h3>
            <span style="color:red;">FRIENDSHIP LINK</span>
          </td>
          <td>
            <ul class="hui" style="color:darkgray;">
              <li>MMA官网</li>
              <li>中国格斗协会</li>
              <li>中国武术协会</li>
              <li>武林风</li>
              <li>UFC</li>
              <li>昆仑决</li>
              <li>运动员联盟</li>
              <li>中国体育</li>
            </ul>
          </td>
        </tr>
      </table>
      <div style="text-align:center;color:darkgray; background-color: #181818;">
        &copy;2024星云恒宇（北京）国际体育科技发展有限公司 版权所有 <span style="color:white;">蜀ICP备2024095508号</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer{
  width: 100%;
  height: 320px;
  margin-top:20px;
  float: left;
  clear: both;
  background-color: #181818;
}

.footer-center{
  width: 76%;
  height: 300px;
  margin: 0px auto;
}

.footer-center ul{
  padding:0px;
  margin: 0px;
}

.footer-center li{
  float: left;
  height: 30px;
  width: 260px;
  text-align: center;
  list-style: none;
}
</style> 