<template>
  <div class="main">
    <div class="main-center">
      <!-- 播放器 -->
      <div class="video-player">
        <!-- 阿里嵌入式播放器 -->
        <!-- <div class="prism-player" id="J_prismPlayer" style="height:510px;overflow-x: hidden;overflow-y: hidden;"></div> -->
        <iframe style="width:100%;height:510px;overflow-x: hidden;overflow-y: hidden;" src="//player.bilibili.com/player.html?isOutside=true&aid=10595207&bvid=BV1Vx411v7jM&cid=17490622&p=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
      </div>
      <!-- 直播赛事列表 -->
      <div class="contest-live-list">
        <p class="p1">
          <img class="contest-live-list-img" src="@/assets/livelogo.png">
          <span>直播</span>
          </p>
        <ul>
          <li v-for="item in contestList" :key="item.id" @click="goContest(item)">
            <div class="p1-img-div"><img class="p1-img" :src="item.player1Avatar"/></div>
            <div class="names">
              <p>{{item.player1Name}} VS {{item.player2Name}}</p>
              <p style="color:#FA0202">{{item.place}}</p>
              <p><img  style="height:30px;margin-top:-10px" src="@/assets/data.png"></p>
            </div>
            <div class="p2-img-div"><img class="p2-img" :src="item.player2Avatar"/></div>
          </li>
        </ul>
      </div>
      <!-- 赛事列表 -->
      <div class="contest-list">
        <ul>
          <li v-for="item in contestList" :key="item.id" >
            <img class="left-img" :src="item.cover" @click="goContest(item)">

            <div class="center-vs">
              <h3 class="center-vs-status" :class="{doing: item.liveStatus == 2}">
                {{ item.liveStatus==3 ? '已结束' : (item.liveStatus==1 ? '未开始' : '进行中!!!') }}
              </h3>
              <p class="center-vs-date">{{ item.playTime }}</p>
              <p class="center-vs-date"> </p>

              <div class="center-vs-p1-img-div"><img class="center-vs-p1-img" :src="item.player1Avatar"/></div>
              <div class="center-vs-names">
                <h3>{{item.player1Name}} VS {{item.player2Name}}</h3>
                <h3 style="color:#FA0202">{{item.place}}</h3>
              </div>
              <div class="center-vs-p2-img-div"><img class="center-vs-p2-img" :src="item.player2Avatar"/></div>

            </div>
            <div class="right-buttons">
              <el-button v-if="item.liveStatus==3" class="el-button-replay" type="danger" icon="el-icon-video-play" style="background-color: #FA0202;" @click="goContest(item)">视频回看</el-button>
              <el-button v-if="item.liveStatus==3" class="el-button-data" type="danger" icon="el-icon-s-data" style="background-color: #FA0202;" @click="analysis(item)">数据分析</el-button>
            </div>
          </li>
        </ul>
        <!-- 分页 -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pagesize"      
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
      </div>  
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components:{

  },
  props: {
  },
  data() {
    return{
      navList:[],
      contestList:[],
      currentPage:1, 
      pagesize:4,    
      total:1,
      activeLink: null,
      };
    },
  mounted(){
    // 阿里播放器配置
    // this.player = new Aliplayer({
    //     id: 'J_prismPlayer',
    //     width: '100%',
    //     height: '900px',
    //     autoplay: true,
    //     //支持播放地址播放,此播放优先级最高
    //     source : "//player.alicdn.com/video/aliyunmedia.mp4",
    // });
  },
  created(){
    this.activeLink = this.$route.query.link  ;
    // 初始化页面数据
    this.init();
    this.handleData();
  },
  methods: {
    init(){
      // 加载导航
      axios.get('/cms/front/channelList?siteId=2')
        .then(response => {
          this.navList = response.data.data;
          // console.log(response.data);
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });
        // 加载赛事动态
      axios.get('/cms/front/contestPages')
        .then(response => {
          this.contestList = response.data.rows;
          this.total = response.data.total;
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });  
    },
    go(link){
      if(link == null || link == ''){
        this.$router.push("/");
      }else{
        this.$router.push({
          path: link,
          query: {'link': link}
        });
      }
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      console.log(this.currentPage)  //点击第几页
    },
    handleData() {
      // 加载所有通知公告
      axios.get('/cms/front/contestPages')
        .then(response => {
          this.contestList = response.data.rows;
          this.total = response.data.total;
        })
        .catch(error => {
          console.error('请求后端接口失败:', error);
        });
    },
    goContest(item){
      if(item.liveStatus == 1){
        alert("比赛尚未开始！")
      }else{
        this.$router.push({
          path: '/contestDetail',
          query: {'contestId': item.id}
        });
      }
    },
    analysis(item){
      this.$router.push({
          path: '/contestAnalysis',
          query: {'contestId': item.id}
        });
    }
  }
}
</script>

<style scoped>
.main{
  background-color: black;
  border:1px solid black;
  /* height: 2650px; */
  font-size: 12px; 
}
ul,li{
  margin:0px;
  padding:0px;
}
.main-center{
    width: 76%;
  margin: 0px auto;
}
.top-menu li:hover{
  cursor: pointer;
  color: aliceblue;
}

.liActive{
  font-weight: bold;
  color: honeydew;
}
.top{
  border:1px solid black;
  height: 80px;;
  background-color: black;
}
.logo{
  width: 128px;
  height: 68px;
  float: left;
  position: relative;
  top: 8px;
}
.top-menu {
  width: 600px;
  height: 40px;
  font-size: 12px;
  background-color: red;
  border-radius: 6px;
  color: black;
  float: left;
  margin-left: 30px;;
  margin-top: 30px;
}
.top-menu li{
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
}
a{
  text-decoration: none;
}
.top-menu-right img{
  height: 46px;
  float: right;
  position: relative;
  top:-3px;
  left: 40px;;
}
.top-menu ul {
  list-style-type: none;
  padding: 0;
}
.top-menu li {
  display: inline-block;
  margin: 0 10px;
}

.user{
  float: right;
  margin-top:30px;
  margin-right: 20px;
}

.video-player{
  width: 70%;
  height: 510px;
  /* border:1px solid red; */
  /* background-color: #181818; */
  float: left;
  padding:0px;
  margin:0px;
}

.contest-live-list{
  width: 29%;
  height: 510px;
  /* border:1px solid red; */
  float: right;
  background-color: #181818;
}
.contest-live-list .p1{
  height: 30px;
  color: white;
  line-height: 30px;
  border-bottom:1px dotted red;
}
.contest-live-list p span{
  height: 30px;
  line-height: 30px;
  position: relative;
  top:-17px;
  left: 5px;
  font-size: 1.2em;
}
.contest-live-list li{
  list-style: none;
  background-color: #701906;
  border-radius: 3px;
  width: 350px;
  height: 100px;
  margin:14px;
}
.contest-live-list-img{
  width: 30px;
  position: relative;
  top:-5px;
}
.p1-img-div{
  float: left;
  width: 80px;
  margin-left: 5px;
}
.p2-img-div{
  float: right;
  width: 90px;
  margin-right: 5px;
}
.names{
  float: left;
  width: 150px;;
  height: 100px;
  color: white;
  margin-left: 20px;;
}
.names p{
  height: 15px;
  line-height: 15px;
  text-align: center;
  vertical-align: middle;
}
.p1-img,.p2-img{
  left: left;
  width: 90px;
  height: 90px;
  /* margin-left: 30px; */
  vertical-align: middle;
  background-color: #701906;
}

.contest-list{
  width: 100%;
  min-height: 1000px;
  /* border:1px solid red; */
  margin-top: 30px;
  float: left;
}
.contest-list li{
  width: 98%;
  margin: 0px auto;
  height: 200px;
  background-color: #181818;
  border-radius: 4px;
  padding:10px;
  border-radius: 8px;
  margin-top:15px;
}
.contest-list .left-img{
  width: 280px;
  height: 200px;
  float: left;
  border-radius: 8px;
}
/* ============================================================================== */
.contest-list .center-vs{
  /* border:1px solid darkturquoise; */
  width: 550px;
  height: 200px;
  float: left;
  margin-left:70px;

}
.center-vs-status{
  color: #808080;
  text-align: center;
  margin:0px;
  padding:0px;
  margin-top:10px;  
  margin-left:-30px;
}
.center-vs-date{
  color:white;
  text-align: center;
  margin:0px;
  padding:0px;
  margin-top:20px;
  margin-left:-30px;
}
.contest-list .center-vs .center-vs-p1-img-div,.center-vs-names,.center-vs-p2-img-div{
  background-color:  #701906;
  float: left;
  margin-top:0px;
}
.contest-list .center-vs .center-vs-names{
  width: 300px;
  height: 93px;
  color:white;
  text-align: center;
}
.contest-list .center-vs .center-vs-p1-img,.center-vs-p2-img{
  width: 90px;
  height: 90px;
}
.contest-list .center-vs .center-vs-p1-img{
  margin-left:20px
}
.contest-list .center-vs .center-vs-p2-img{
  margin-right:20px
}
/* ============================================================================== */
.contest-list .right-buttons{
  width: 320px;
  height: 200px;
  float: right;
  margin-right: 20px;;
  
}
.contest-list .el-button-replay{
  margin:0px auto;
  margin-top:80px;
  display: block;
}
.contest-list .el-button-data{
  margin:0px auto;
  margin-top:12px;
  display: block;
}


.footer{
  width: 100%;
  height: 320px;
  margin-top:20px;
  /* border:1px solid black; */
  float: left;
  clear: both;
  background-color: #181818;
}

.footer-center{
  width: 80%;
  height: 300px;
  margin: 0px auto;
  /* border:1px solid black; */
}
.footer-center ul{
  padding:0px;
  margin: 0px;
}
.footer-center li{
  float: left;
  height: 30px;
  width: 260px;
  text-align: center;
  list-style: none;
}

::v-deep .el-pagination .el-pager li:not(.active):not(.disabled):hover {
  background-color: white !important;
  color: red;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: red;
  color:white;
}
::v-deep .el-pagination.is-background .el-pager  li:not(.disabled){
	background-color: transparent !important;
  color:#ff2832;
}
::v-deep .el-pagination.is-background  .btn-prev{
	background-color: transparent !important;
  color:#ff2832;
}
::v-deep .el-pagination.is-background  .btn-next{
	background-color: transparent !important;
  color:#ff2832;
}
.el-pagination {
  /* border:1px solid red;  */
  text-align: center;
  margin-top: 20px;;
}
.doing{
  color: red;
}
</style>
